export const getDay = (data) => {
  const res = data.split(' ');
  let day = res[0].split('-');
  day[2] = parseInt(day[2]);
  return day[2];
};
export const getMonth = (data) => {
  const res = data.split(' ');
  let month = res[0].split('-');
  return 'month' + month[1];
};
export const getYear = (data) => {
  let res = data.split(' ');
  let year = res[0].split('-');
  return year[0];
};

