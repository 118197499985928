<template>
  <div v-if="loaded">
    <div class="my-account-back-to-dashboard">
      <SvgImage
        class="my-account-back-to-dashboard__icon"
        height="10"
        icon="Icon_fleche_gauche"
        width="10"
      />
      <SfLink
        :link="localeRoute({ name: 'customer-dashboard' })"
        class="my-account-back-to-dashboard__text"
      >
        Mon tableau de bord
      </SfLink>
    </div>

    <div class="my-account-heading">
      <h2 class="my-account-heading__title">
        {{ $t('Orders waiting for approval') }}
      </h2>
    </div>

    <div class="orders-waiting-for-approval">

      <div
        v-if="orders && orders.length === 0"
        class="orders-waiting-for-approval__no-orders"
      >
        <span>{{ $t('Currently, you have no orders to validate') }}</span>
      </div>

      <div
        v-else
        class="orders-waiting-for-approval__list"
      >

        <template v-for="(order, i) in orders">
          <div
            :key="i"
            class="listApproval"
          >
            <div class="pictime-order-card">
              <div class="pictime-order-card__info">
                <span class="pictime-order-card__info--heading">
                  {{ $t('Order passed') }}
                  {{ getDay(order.request_for_approval_created_at) }}
                  {{ $t(getMonth(order.request_for_approval_created_at)) }}
                  {{ getYear(order.request_for_approval_created_at) }}
                </span>N°{{ order.entity_id }} - {{ $t('Online') }}
              </div>

              <div class="pictime-order-card__info">
                <span class="pictime-order-card__info--heading">{{ $t('Order by') }}</span>{{ order.customer_firstname }} {{
                  order.customer_lastname
                }}
              </div>
              <div class="pictime-order-card__info">
                <span class="pictime-order-card__info--heading">
                  {{
                    (order.prices.subtotal_excluding_tax.value - (order.applied_coupons ? parseFloat((order.applied_coupons[0].value)) : 0)).toFixed(2) + $t(order.prices.subtotal_excluding_tax.currency) + ' ' + $t('Excluding tax')
                  }}
                </span>
                {{ order.prices.grand_total.value + $t(order.prices.subtotal_including_tax.currency) + ' ' + $t('Including tax') }}
              </div>
              <SfLink
                :link="localeRoute({ name: 'customer-order-waiting-for-approval-detail', params: { orderId: String(order.entity_id) } } )"
                class="sf-button sf-button__primary pictime-order-card__button"
              >
                {{ $t('Show details') }}
              </SfLink>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { SfButton, SfLink } from '@storefront-ui/vue';
import SvgImage from '~/components/General/SvgImage.vue';
import { ref, useFetch } from '@nuxtjs/composition-api';
import { useOrderWaitingForApproval } from '~/composables';
import { getDay, getMonth, getYear } from '~/helpers/date';

export default {
  components: {
    SfLink,
    SvgImage,
    SfButton
  },
  middleware: 'is-dirigeant-account',
  setup() {
    const { getList } = useOrderWaitingForApproval();

    const loaded = ref(false);
    const orders = ref({});
    useFetch(async () => {
      let list     = await getList();
      orders.value = list.sort((a, b) => new Date(a.request_for_approval_created_at) - new Date(b.request_for_approval_created_at));
      loaded.value = true;
    });

    return {
      loaded,
      orders,
      getDay,
      getMonth,
      getYear
    }
  }
}
</script>

<style lang='scss'>
@import '@/modules/customer/pages/styles/shared.scss';
@import '@/modules/customer/pages/styles/order-card.scss';
</style>
